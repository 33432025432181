<template>
  <div class="prize-amount" :class="props.size">
    <StIcon
      v-if="isRiseUp && props.size !== 'xs'"
      class="rise-up"
      name="rise-up"
    />
    <strong class="amount">{{ amount }}</strong>
    <StIcon
      v-if="currencyIcon && props.size !== 'xs'"
      class="currency-icon"
      :name="currencyIcon"
      :size="currencyIconSize"
    />
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '@st/ui/components/StIcon/types'

const props = withDefaults(
  defineProps<{
    amount: string
    currencyIcon?: IconName | null
    isRiseUp?: boolean
    size?: 'xs' | 's' | 'm' | 'ml' | 'l'
  }>(),
  {
    size: 'm',
  },
)

const currencyIconSize = computed(() => {
  switch (props.size) {
    case 'xs':
      return 16
    case 's':
      return 18
    case 'm':
      return 24
    case 'ml':
      return 24
    case 'l':
      return 36
    default:
      return 24
  }
})
</script>

<style scoped>
.amount {
  padding-right: var(--spacing-025);

  /* stylelint-disable */
  font-family: 'PF Din Text Comp Pro', sans-serif;
  font-size: 56px;
  font-weight: 700;
  font-style: italic;
  line-height: 100%;
  /* stylelint-enable */
  text-shadow: 0 4px 32px rgb(255 222 122 / 16%);
  text-transform: uppercase;

  background: conic-gradient(
    from 176deg at 45.83% 38.01%,
    #fdc065 129.0455deg,
    #ffe37e 295.568deg,
    #fd9c65 360deg
  );
  background-clip: text;

  -webkit-text-fill-color: transparent;
}

.rise-up {
  margin-top: 9px; /* stylelint-disable-line */
  color: var(--icon-success);
}

.currency-icon {
  margin-left: var(--spacing-100);
  margin-top: 3px; /* stylelint-disable-line */
}

.prize-amount {
  display: flex;
  align-items: flex-start;

  &.l {
    .amount {
      padding-right: var(--spacing-025);
      /* stylelint-disable */
      font-size: 84px;
      line-height: 74px;
      /* stylelint-enable */
    }

    .currency-icon {
      margin-top: 0;
    }
  }

  &.ml {
    .amount {
      padding-right: var(--spacing-025);
      /* stylelint-disable */
      font-size: 48px;
      line-height: 56px;
      /* stylelint-enable */
    }
  }

  &.s {
    justify-content: center;

    .amount {
      padding: 0 var(--spacing-050);
      /* stylelint-disable */
      font-size: 36px;
      line-height: 36px;
      /* stylelint-enable */
    }

    .currency-icon {
      margin-top: 0;
      margin-left: var(--spacing-075);
    }

    .rise-up {
      margin-top: 0;
    }
  }

  &.xs {
    justify-content: center;
    padding: 0 var(--spacing-050);

    .amount {
      /* stylelint-disable */
      font-size: 24px;
      line-height: 28px;
      /* stylelint-enable */
    }

    .currency-icon {
      margin: 0;
    }

    .rise-up {
      margin-top: 0;
    }
  }
}
</style>
